import React, { useEffect, useState, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import PaymentIcon from "@mui/icons-material/Payment";
import { styled } from "@mui/material/styles";
import "../css/StepperCheckout.css";
import Cart from "./Cart";
import Address from "./Address";
import Payment from "./Payment";
import Loader from "../Components/Loader";
import { placeOrder } from "../admin/Categories/services/cartService";
import { ToastContainer, toast } from "react-toastify"; // Make sure this is correct
import "react-toastify/dist/ReactToastify.css"; // Import the CSS here
import Swal from "sweetalert2"; // Import SweetAlert2
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { services } from "../services/services";
import { updateUser } from "../admin/Categories/services/authService";
// Custom Step Connector to adjust line appearance
const CustomStepConnector = styled(({ className, completed }) => (
  <div className={className} />
))(({ theme, completed }) => ({
  height: 3,
  backgroundColor: completed ? "#008080" : theme.palette.grey[300],
  position: "absolute",
  left: "100%",
  transform: "translateX(-50%)",
  width: "calc(100% - 65px)", // Adjust width based on icon size
  top: "30%", // Center line vertically with respect to the icons
  zIndex: 0,
  "&.Mui-active": {
    backgroundColor: "yellow",
  },
}));

const StepperComponent = ({
  cartObj,
  finalCartObj,
  onDelete,
  onAddFavorites,
  onQuantityChange,
  onUpdateFinalCart,
  onCartClear,
  currentUser,
  setNewUser,
  isLoggedIn,
  appLoading,
}) => {
  console.log(cartObj, "cart StepperComponent");
  console.log(finalCartObj, "finalCart StepperComponent");
  console.log(currentUser, "currentUser StepperComponent");
  const [cart, setCart] = useState();
  const [finalCart, setFinalCart] = useState([]);

  // Update cart state when cartObj changes
  useEffect(() => {
    if (cartObj) {
      setCart(cartObj);
      setFinalCart(finalCartObj);
      createOrder();
    }
  }, [cartObj, finalCartObj]); // Only run when cartObj changes

  const [activeStep, setActiveStep] = useState(0);
  const [addressObject, setAddressObject] = useState(null);
  const [validateFields, setValidateFields] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    id: "",
    fullName: "",
    primaryPhone: "",
    address: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    state: "",
  });

  const steps = [
    { label: "Cart", icon: <ShoppingCartIcon sx={{ color: "#008080" }} /> },
    { label: "Address", icon: <HomeIcon sx={{ color: "#008080" }} /> },
    { label: "Payment", icon: <PaymentIcon sx={{ color: "#008080" }} /> },
  ];

  const handleDeleteCart = async (item) => {
    onDelete(item);
  };
  const handleAddFavorite = async (item) => {
    onAddFavorites(item);
  };

  const handleClearCart = async () => {
    onCartClear();
  };

  const handleQuantityChange = async (item, quantity) => {
    onQuantityChange(item, quantity);
  };

  const handleUpdateFinalCart = async (paymentMethod) => {
    onUpdateFinalCart(paymentMethod);
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    // Restrict input to digits only for phone and pincode fields
    if (name === "primaryPhone") {
      updatedValue = value.replace(/\D/g, "").slice(0, 10); // Allow only 10 digits
    } else if (name === "pincode") {
      updatedValue = value.replace(/\D/g, "").slice(0, 6); // Allow only 6 digits
    }
    // Update form data
    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));

    // Clear errors as soon as user starts typing
    if (errors[name] && updatedValue) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    console.log(formData, "formData");
  };

  // Create a ref for the Address component
  const addressRef = useRef(null);

  // Content for each step
  const stepContent = [
    <Box key="cart" className="container">
      <Cart
        cartObj={cartObj}
        finalCartObj={finalCartObj}
        onDelete={handleDeleteCart} // Pass delete function as a prop
        onAddFavorites={handleAddFavorite} // Pass delete function as a prop
        onQuantityChange={handleQuantityChange} // Pass delete function as a prop
        currentUser={currentUser}
        isLoggedIn={isLoggedIn}
        setNewUser={setNewUser}
      />
    </Box>,
    <Box key="address" className="container">
      <Address
        ref={addressRef} // Pass the ref to the Address component
        setAddressObject={setAddressObject}
        cartObj={cartObj}
        finalCartObj={finalCartObj}
        handleChange={handleChange}
        setErrors={setErrors}
        errors={errors}
        formData={formData}
        currentUser={currentUser}
        setNewUser={setNewUser}
        isLoggedIn={isLoggedIn}
        setFormData={setFormData}
      />
    </Box>,
    <Box key="payment" className="container">
      <Payment
        cartObj={cartObj}
        finalCartObj={finalCartObj}
        formData={formData}
        onUpdateFinalCart={handleUpdateFinalCart}
        currentUser={currentUser}
        isLoggedIn={isLoggedIn}
        appLoading={appLoading}
      />
    </Box>,
  ];

  const [isValid, setIsValid] = useState(false);

  const handleNext = () => {
    if (finalCart.items > 0) {
      if (activeStep == 0) {
        // alert(1);
        setActiveStep(1);
      } else if (activeStep == 1) {
        if (addressRef.current) {
          const isValid = addressRef.current.validateFields(); // Call validateFields
          if (isValid) {
            setIsValid(true);
            console.log("All fields are valid. Proceed to next step.");
            if (activeStep < steps.length - 1) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            if (currentUser?.id) {
              // alert("handleUpdateAddress");
              handleUpdateAddress();
            }
          } else {
            console.log("There are validation errors.");
            toast.error("Please provide information for all fields.", {
              position: "top-center",
              autoClose: 2000,
            });
          }
        }
      } else if (activeStep == 2) {
        handlePlaceOrder();
      }
    } else {
      navigate("/");
    }
  };

  const handleUpdateAddress = async () => {
    // Generate a unique ID for the new address
    const newAddress = {
      id: formData?.id ? formData?.id : new Date().toISOString(), // You can use any method to generate a unique ID
      fullName: formData.fullName,
      primaryPhone: formData.primaryPhone,
      address: formData.address,
      landmark: formData.landmark,
      pincode: formData.pincode,
      city: formData.city,
      state: formData.state,
    };
    const updatedData = {
      addresses: [newAddress], // Ensuring the address is an array
    };
    try {
      await updateUser(currentUser?.id, updatedData);
      // alert("User updated successfully!");
      setNewUser();
    } catch (error) {
      if (error.response) {
        console.error("Error response from server:", error.response.data);
      } else {
        console.error("Error updating user data:", error);
      }
      // alert("Failed to update user.");
    }
  };

  const handlePlaceOrder = async () => {
    placeCustomerOrder();

    //enable below code when doing online payment again
    // if (finalCart?.deliveryCharge > 0) {
    //   placeCustomerOrder();
    // } else {
    //   // placeCustomerOrder();
    //   openRazorpay();
    // }
  };

  const placeCustomerOrder = async () => {
    // alert("placeCustomerOrder");
    try {
      const orderDetails = {
        customerAddress: formData,
        fullCart: cart,
        finalCart: finalCart,
        orderDate: new Date(), // Store the date of the order
        userId: currentUser?.id ? currentUser?.id : "Guest User",
      };
      // Call the API to place the order
      const response = await placeOrder(orderDetails);
      if (response.status === 201) {
        toast.success("Order placed successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        handleClearCart();
        navigate("/ordered-successfully");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Error placing order!", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const handleBack = () => {
    // alert("handleBack");
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else if (activeStep == 0) {
      // alert("d");
      if (location.state?.from) {
        // alert("s");
        navigate(location.state.from); // Navigate to the previous location
      } else {
        navigate("/"); // Fallback to the specific URL
      }
    }
  };

  useEffect(() => {
    console.log(addressObject, "addressObject");
  }, [addressObject]);

  // useEffect(() => {
  //   console.log(cart, "cart");
  // }, [cart]);

  // useEffect(() => {
  //   console.log(finalCart, "finalCart");
  // }, [finalCart]);

  useEffect(() => {
    console.log(activeStep, "activeStep");
  }, [activeStep]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formData && Object.keys(formData).length > 0) {
        event.preventDefault();
        event.returnValue = ""; // Triggers the basic browser alert
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formData]);

  const [orderId, setOrderId] = useState(null);

  const createOrder = async () => {
    try {
      const response = await axios.post(
        `${services?.RAZORURL}payments/initiate`,
        {
          amount: 1, // Amount in INR (500 INR)
          currency: "INR", // Currency for the transaction
          receipt: "receipt#1", // Unique identifier for this payment
        }
      );

      if (response.data.orderId) {
        setOrderId(response.data.orderId); // Save the order ID to state
        console.log("Order created:", response.data.orderId);
      } else {
        console.error("Error creating order:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const openRazorpay = async () => {
    setIsPaymentLoading(true);
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      setIsPaymentLoading(false);
      toast.error("Razorpay failed to load. Are you online?", {
        position: toast?.POSITION?.TOP_CENTER,
        autoClose: 3000,
      });
      return;
    }
    // Create order by calling the backend
    const orderData = await fetch(
      `${services?.RAZORURL}payments/create-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: finalCart?.totalPrice * 100,
          currency: "INR",
          receipt: "receipt#1",
        }), // Amount in paise
      }
    ).then((response) => response.json());
    console.log(orderData, "orderData");
    setIsPaymentLoading(false);

    if (!orderData.orderId) {
      setIsPaymentLoading(false);

      toast.error("Error creating order. Please try again.", {
        position: toast?.POSITION?.TOP_CENTER,
        autoClose: 3000,
      });
      return;
    }

    const options = {
      key: "rzp_live_Rg5roT6QcJHuL4", // Replace with your Razorpay Key ID
      amount: orderData.amount, // Amount in currency subunits (paise)
      currency: orderData.currency,
      name: "Complete your payment",
      description: "Purchase Product",
      order_id: orderData.orderId, // Order ID generated by backend
      handler: async (response) => {
        // Send payment details to backend for verification
        const verificationResponse = await fetch(
          `${services?.RAZORURL}payments/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            }),
          }
        ).then((res) => res.json());

        if (verificationResponse.message === "Payment verified successfully") {
          placeCustomerOrder();
          toast.success("Payment Successful!", {
            position: toast?.POSITION?.TOP_CENTER,
            autoClose: 3000, // Auto close after 3 seconds
          });
        } else {
          toast.error("Payment Verification Failed", {
            position: toast?.POSITION?.TOP_CENTER,
            autoClose: 3000,
          });
        }
      },
      prefill: {
        name:
          formData?.fullName &&
          formData.fullName.charAt(0).toUpperCase() +
            formData.fullName.slice(1).toLowerCase(),
        email: "contact.globalindiashop@gmail.com",
        contact: formData?.primaryPhone,
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  useEffect(() => {}, []);

  if (isPaymentLoading) {
    return <Loader isPaymentLoading={isPaymentLoading} />; // Show loader while loading
  }

  return (
    <>
      <div className="stepper-main">
        <Box sx={{ width: "100%", position: "relative" }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              "& .MuiStepConnector-root": {
                display: "none", // Hides all Step Connectors
              },
            }}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  icon={step.icon}
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      backgroundColor:
                        activeStep === index ? "#ffffff" : "unset",
                      borderRadius: "50%",
                      color: "#008080", // Always set icon color
                      width: {
                        xs: "40px", // 40px for mobile (xs)
                        sm: "50px", // 50px for small screens and up
                      },
                      height: {
                        xs: "40px", // 40px for mobile (xs)
                        sm: "50px", // 50px for small screens and up
                      },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& .MuiStepLabel-label": {
                      marginTop: "8px !important", // Apply top margin of 8px to all labels, force with !important
                    },
                    "&.Mui-active .MuiStepLabel-label": {
                      marginTop: "8px !important", // Force top margin of 8px for active label
                      backgroundColor: "#fff !important", // Add white background color
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
                {index < steps.length - 1 && (
                  <CustomStepConnector completed={activeStep > index} />
                )}
              </Step>
            ))}
          </Stepper>

          {/* Render content based on the active step */}
          <Box sx={{ mt: 2 }}>{stepContent[activeStep]}</Box>

          <div className="container">
            <div className="buy-button-box">
              <button className="add-cart-button" onClick={handleBack}>
                <span>Back</span>
              </button>
              <button className="buy-now-button" onClick={handleNext}>
                <span>
                  {finalCart &&
                  finalCart.items > 0 &&
                  finalCart.totalPrice >= 0 ? (
                    <>
                      {activeStep === 0
                        ? "Enter Address"
                        : activeStep === 1
                        ? "Continue Payment"
                        : activeStep === 2
                        ? "Place Order"
                        : null}{" "}
                    </>
                  ) : (
                    <>
                      {activeStep === 0
                        ? "Continue Shopping"
                        : activeStep === 1
                        ? "Continue Payment"
                        : activeStep === 2
                        ? "Place Order"
                        : null}{" "}
                    </>
                  )}

                  {/* Optional null for safety */}
                </span>
              </button>
            </div>
            {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>

          <Button
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
          >
            Next
          </Button>
        </Box> */}
          </div>
        </Box>
      </div>
    </>
  );
};

export default StepperComponent;
