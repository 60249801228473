// src/components/ProductView.js
import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ImagePreview from "./ImagePreview";
import "../css/productview.css";
import TrustCards from "./TrustCards";
import TabComponent from "./TabComponent";
import Reviews from "./Reviews";
import MainFooter from "../Components/MainFooter";
import { IconButton, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify"; // Make sure you import ToastContainer too
import { Add, Remove } from "@mui/icons-material";
import ResponsiveTabs from "../Components/ResponsiveTabs";
import { getProductById } from "../admin/Products/productService";
import {
  startSession,
  addToCart,
} from "../admin/Categories/services/cartService";
import { services } from "../services/services";
import Color from "color"; // Import the color package
import Loader from "../Components/Loader";
import { PlayCircleOutline } from "@mui/icons-material"; // Icon for videos if desired
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Import the new play icon
import ReactPlayer from "react-player";

const ProductView = () => {
  const { id } = useParams();
  console.log(id, "id");
  const [product, setProduct] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const tabLabels_1 = ["Mens", "Womens", "Couples Watches"];
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();
  const location = useLocation();
  const reviewRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [sizeError, setSizeError] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("Enter pincode to check");
  const [quantity, setQuantity] = useState(1);
  const [sessionId, setSessionId] = useState(null);
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedMedia, setSelectedMedia] = useState(null); // To track selected image or video URL
  const [isVideo, setIsVideo] = useState(false); // To track if the selection is a video
  const [embedUrl, setEmbedUrl] = useState(null); // To track the generated embed URL

  useEffect(() => {
    if (isVideo && selectedMedia) {
      const getVideoId = (url) => {
        const urlObj = new URL(url);
        const pathnameParts = urlObj.pathname.split("/");
        return pathnameParts[pathnameParts.length - 1];
      };
      const videoId = getVideoId(selectedMedia);
      const isShort = selectedMedia.includes("/shorts/");
      const src = isShort
        ? `https://www.youtube.com/embed/${videoId}`
        : `https://www.youtube.com/embed/${videoId}?rel=0`;

      setEmbedUrl(src);
    }
  }, [isVideo, selectedMedia]);

  useEffect(() => {
    const fetchSessionId = async () => {
      // Check if session ID already exists in local storage
      let savedSessionId = localStorage.getItem("global_india_shop_sessionId");
      if (!savedSessionId) {
        // If no session ID, fetch a new one from the backend
        try {
          const response = await startSession();
          console.log(response, "response");
          savedSessionId = response.sessionId;
          // Save the session ID to local storage
          console.log(savedSessionId, "savedSessionId");
          localStorage.setItem("global_india_shop_sessionId", savedSessionId);
        } catch (error) {
          console.error("Error fetching session ID:", error);
          return; // Exit if there's an error
        }
      }
      setSessionId(savedSessionId);
    };

    fetchSessionId();
  }, []);

  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    setLoading(true); // Start loading
    try {
      const response = await getProductById(id);
      setProduct(response.data);
    } catch (error) {
      console.error("Error fetching Product", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (product) {
      console.log(product, "product_view");
      setSelectedImage(product?.main_image);
      setSelectedMedia(product?.main_image);

      // Check if colors array exists and has at least one element
      if (product.colors && product.colors.length > 0) {
        setSelectedColor(product.colors[0]); // Set the first color as the default
      } else {
        setSelectedColor(null); // Or set a default value if no colors are available
      }

      // Check if colors array exists and has at least one element
      if (product.imageList && product.imageList.length > 0) {
        setSelectedImage(product.imageList[0]); // Set the first color as the default
        setSelectedMedia(product.imageList[0]); // Set the first color as the default
      } else {
        setSelectedImage(null); // Or set a default value if no colors are available
        setSelectedMedia(null); // Or set a default value if no colors are available
      }

      // Check if sizes array exists and has at least one element
      // if (product.sizes && product.sizes.length > 0) {
      //   setSelectedSize(product.sizes[0]); // Set the first size as the default
      // } else {
      //   setSelectedSize(null); // Or set a default value if no sizes are available
      // }
    }
  }, [product]);

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleQuanitiyChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setQuantity(Number(value));
    }
  };

  const scrollToReviews = () => {
    if (reviewRef.current) {
      reviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const selectImage = (image) => {
    setSelectedImage(image);
  };

  // State to store the selected color

  // Function to handle color click
  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
    setSizeError(false);
  };

  const checkout = async () => {
    setLoading(true);
    if (!selectedSize && product?.type == "clothing") {
      setSizeError(true);
      setLoading(false);
      toast.error("Please select size", {
        position: toast?.POSITION?.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      setLoading(true);
      const cartItem = {
        name: product.name,
        type: product.type,
        price: product.price,
        before_price: product.before_price,
        discount: product.discount,
        savedPrice: product.price - product.before_price,
        color: Color(selectedColor).keyword(), // Assuming you have a way to select the color
        size: selectedSize, // Assuming you have a way to select the size
        quantity: quantity,
        main_image: selectedImage,
        product_id: product?._id,
      };
      if (sessionId) {
        try {
          setLoading(false);
          await addToCart(sessionId, cartItem); // Call the addToCart function
          console.log("Item added to cart");
          // navigate(`/checkout`, {
          //   state: { product: product },
          // });
          navigate(`/checkout`, {
            state: { product: product, from: location.pathname },
          });
          // Optionally refresh the cart or notify the user
        } catch (error) {
          setLoading(false);
          console.error("Error adding item to cart:", error);
        }
      }
    }
  };

  // useEffect to update selectedImage when selectedColor changes
  useEffect(() => {
    if (selectedColor) {
      const matchedImage = product?.imagesArray?.find(
        (image) => image.color === selectedColor
      );
      if (matchedImage) {
        setSelectedImage(matchedImage.image);
        setSelectedMedia(matchedImage.image);
      }
    }
  }, [selectedColor]);

  // useEffect to update selectedColor  when selectedImage changes
  useEffect(() => {
    if (selectedImage) {
      const matchedImage = product?.imagesArray?.find(
        (image) => image.image === selectedImage
      );
      console.log(matchedImage, "matchedImage");
      if (matchedImage) {
        setSelectedColor(matchedImage.color);
      }
    }
  }, [selectedImage]);

  useEffect(() => {
    console.log(selectedImage, "selectedImage");
  }, [selectedImage]);

  useEffect(() => {
    console.log(selectedColor, "selectedColor");
  }, [selectedColor]);

  useEffect(() => {
    console.log(selectedSize, "selectedSize");
  }, [selectedSize]);

  useEffect(() => {
    console.log(embedUrl, "embedUrl");
  }, [embedUrl]);

  useEffect(() => {
    console.log(sessionId, "sessionId");
  }, [sessionId]);
  useEffect(() => {
    console.log(selectedMedia, "selectedMedia");
  }, [selectedMedia]);

  const handlePincodeChange = (e) => {
    const value = e.target.value;

    // Only allow exactly 6 digits to proceed
    if (value.length === 6) {
      setPincode(value);
      setError(""); // Clear error if valid
    } else {
      setPincode(value);
      if (value.length < 6) {
        setError("Pincode must be exactly 6 digits");
      }
    }
  };

  const handleCheck = () => {
    if (pincode.length !== 6) {
      setError("Please enter a valid 6-digit pincode.");
    } else {
      setError("");
      // Perform further actions like API call
      const today = new Date();
      today.setDate(today.getDate() + 7);
      const options = { day: "2-digit", month: "long", year: "numeric" }; // Use 'long' for full month name
      const formattedDate = today.toLocaleDateString("en-GB", options); // Formats as DD Month YYYY
      setDeliveryDate(`Expected delivery by ${formattedDate}`);
    }
  };

  useEffect(() => {
    // Scroll to top when the component mounts
    fetchProduct();
    window.scrollTo(0, 0);
  }, [id]); // Add `id` as a dependency to run this effect whenever the product ID changes

  if (loading) {
    return <Loader loading={loading} />; // Show loader while loading
  }

  const selectMedia = (media) => {
    if (media.includes("youtube.com")) {
      setIsVideo(true);
      setSelectedMedia(media.replace("watch?v=", "embed/")); // Convert to embedded format
      // setSelectedImage(null);
    } else {
      setIsVideo(false);
      setSelectedMedia(media);
      setSelectedImage(media);
    }
  };

  // Function to extract the video ID from a YouTube URL
  const getVideoId = (url) => {
    const urlObj = new URL(url);
    const pathnameParts = urlObj.pathname.split("/");
    return pathnameParts[pathnameParts.length - 1];
  };

  return (
    <>
      <div className="container p-3">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
            <div className="product-gallery">
              {/* <div className="main-image-div">
                <img
                  className="main-image"
                  src={`${selectedImage?.replace(/\\/g, "/")}`}
                  alt="Product-Image"
                />
              </div> */}
              {/* <div className="product-images">
                {product &&
                  product?.imagesArray?.map((image) => (
                    <>
                      <img
                        onClick={() => selectImage(image?.image)}
                        className={`product-img ${
                          image?.image === selectedImage ? "selected-image" : ""
                        }`}
                        src={`${image.image.replace(/\\/g, "/")}`}
                        alt=""
                      />
                    </>
                  ))}
              </div> */}

              <div className="main-image-div">
                {isVideo && embedUrl ? (
                  // <div className="youtube-product-view">
                  //   <iframe
                  //     width="100%" // Full width of the container
                  //     height="315"
                  //     src={embedUrl}
                  //     title="YouTube video"
                  //     frameBorder="0"
                  //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  //     allowFullScreen
                  //   />
                  // </div>
                  <div className="fixed-height-video-wrapper">
                    <ReactPlayer
                      url={embedUrl}
                      width="100%"
                      height="100%"
                      controls={true}
                      loop={true}
                      config={{
                        youtube: {
                          playerVars: {
                            modestbranding: 1,
                            rel: 0, // Disables related videos at the end
                            showinfo: 0, // Hides title and video info
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <img
                    className="main-image"
                    src={selectedMedia?.replace(/\\/g, "/")}
                    alt="Selected Product Media"
                  />
                )}
              </div>

              <div className="product-images">
                {/* Display product images without duplication */}
                {/* Display YouTube video thumbnails */}

                {/* {product?.youtubeLinks &&
                  product.youtubeLinks.map((link, index) => (
                    <div
                      key={`video-${index}`} // Unique key for each video
                      onClick={() => selectMedia(link)}
                      className={`product-img ${
                        selectedMedia === link ? "selected-media" : ""
                      }`}
                    >
                      <IconButton color="primary">
                        <PlayCircleOutline />
                      </IconButton>
                    </div>
                  ))} */}

                {product?.youtubeLinks &&
                  product.youtubeLinks.map((link, index) => {
                    // Get the background image from the product images
                    const backgroundImage = product.imagesArray[
                      index % product.imagesArray.length
                    ]?.image.replace(/\\/g, "/");
                    return (
                      <>
                        <div
                          className={`video-overlay-container ${
                            selectedMedia === link ? "selected-link" : ""
                          }`}
                        >
                          <div
                            key={`video-${index}`} // Unique key for each video
                            onClick={() => selectMedia(link)}
                            className="video-overlay"
                            style={{
                              backgroundImage: `url(${backgroundImage})`,
                            }} // Using CSS for this in the next section
                          >
                            <div className="video-icon-wrapper">
                              <i className="bi bi-play-circle-fill play-button"></i>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                {product?.imagesArray &&
                  product.imagesArray.map((image, index) => (
                    <img
                      key={`image-${index}`}
                      onClick={() => selectMedia(image.image)}
                      // className={`product-img ${
                      //   image?.image === selectedImage ? "selected-image" : ""
                      // }`}
                      className={`product-img ${
                        image?.image === selectedImage && !isVideo
                          ? "selected-image"
                          : ""
                      }`}
                      src={image.image.replace(/\\/g, "/")}
                      alt={`Product Image ${index}`}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
            <div className="product-details">
              <div className="product-name">{product?.name}</div>
              <div className="product-type">{product?.type}</div>
              {/* <div className="saver-style">Saver Deal</div> */}
              {/* <hr /> */}

              {/* <div className="ordered-people pt-3">
                <img className="wave-icon" src={wave_arrow} alt="" />
                <span>1000+ people ordered this in the last 30 days</span>
              </div> */}
              {/* <div className="star-rating">
                <span className="star filled"></span>
                <span className="star filled"></span>
                <span className="star filled"></span>
                <span className="star filled"></span>
                <span className="star half"></span>
                <span className="star-rating-content">1548 ratings</span>
                <span className="see-ratings" onClick={scrollToReviews}>
                  See Customer Ratings
                </span>
              </div> */}

              <div className="pricing-section">
                <span className="price-style">₹{product?.price}</span>
                <div className="pricing-section">
                  {/* <span className="mrp">M.R.P:</span> */}
                  <span className="previous-price">
                    ₹ {product?.before_price}
                  </span>
                </div>
                <span className="offer-style">({product?.discount} %)</span>
              </div>

              {product?.type != "kurtis" && (
                <>
                  <p className="color-title">
                    Color{" "}
                    <span className="selected-color">
                      {selectedColor && <>({Color(selectedColor).keyword()})</>}
                    </span>
                  </p>
                  <div className="color-section mb-2">
                    <div className="colors-wrapper">
                      <div className="set-color-box">
                        {product?.colors?.map((color) => (
                          <>
                            <div
                              className={`${
                                selectedColor === color ? "color-outer-box" : ""
                              }`}
                            >
                              <button
                                className="color-inner-box"
                                key={color}
                                style={{
                                  backgroundColor: color,
                                }}
                                onClick={() => handleColorClick(color)}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(product?.type == "clothing" || product?.type == "kurtis") && (
                <>
                  <p className="color-title">
                    Size{" "}
                    {selectedSize && (
                      <>
                        <span className="selected-color">({selectedSize})</span>
                      </>
                    )}
                  </p>
                  {sizeError && (
                    <>
                      <small className="error">Please select a size</small>
                    </>
                  )}
                  <div className="color-section size-section mb-2">
                    <div className="colors-wrapper">
                      <div style={{ display: "flex", gap: "10px" }}>
                        {product?.sizes?.map((size) => (
                          <button
                            key={size} // Use 'size' instead of 'color' as the key to avoid issues
                            className={` size-button ${
                              selectedSize === size ? "selected-size" : ""
                            } `}
                            onClick={() => handleSizeClick(size)}
                          >
                            {size}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="product-qty-container">
                <div className="choose-qty">Select Quantity</div>
                <IconButton
                  className="quantity-button decrease-btn"
                  onClick={handleDecrease}
                  disabled={quantity <= 1}
                >
                  <Remove />
                </IconButton>
                <TextField
                  className="quantity-input"
                  value={quantity}
                  onChange={handleQuanitiyChange}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                />
                <IconButton
                  className="quantity-button increase-btn"
                  onClick={handleIncrease}
                >
                  <Add />
                </IconButton>
              </div>

              <div className="pincode-section">
                <div className="pincode-container">
                  <p className="delivery-title">Delivery Details</p>

                  <div className="pincode-main">
                    <div className="pincode-box">
                      <input
                        type="number"
                        placeholder="Enter Your City Pincode"
                        className="form-control pincode-input"
                        value={pincode}
                        onChange={handlePincodeChange}
                      />
                      <button className="pincode-btn" onClick={handleCheck}>
                        Check
                      </button>
                    </div>
                    {error && <p className="error">{error}</p>}
                  </div>
                  <div className="expected-delivery">
                    Expected delivery By{" "}
                    <span className="expected-delivery-date">
                      ( {deliveryDate} )
                    </span>
                  </div>
                </div>
                <div className="mt-3 mb-2">
                  <TrustCards from="product" />
                </div>
              </div>
              <div className="buy-button-box">
                <button className="add-cart-button" onClick={() => checkout()}>
                  <i className="bi bi-cart-plus-fill bag-icon"></i>
                  <span>Add To cart </span>
                </button>
                <button className="buy-now-button" onClick={() => checkout()}>
                  <span>Buy Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="delivery-title">Product Details</p>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
            <TabComponent product={product} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
            <TrustCards from="product-row-2" />
          </div>
        </div>
        <p className="delivery-title">Similar Products</p>
        <div className="hero-banner mt-3 mb-0">
          <ResponsiveTabs tabLabels={tabLabels_1} from={"edition"} />
        </div>

        {/* <div className="row my-4">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="Ratings-label">Ratings & Reviews</div>
            <h5>Customer reviews</h5>
            <div className="star-rating">
              <span className="star filled"></span>
              <span className="star filled"></span>
              <span className="star filled"></span>
              <span className="star filled"></span>
              <span className="star half"></span>
              <span className="star-rating-content">4.8 out of 5</span>
            </div>
            <div className="total-ratings">262 Ratings</div>
            <hr />
            <h5>Review this product</h5>
            <div className="write-review">
              Leave a review to help other customers make informed decisions
              <span className="review-condition">
                {" "}
                (You can only review the product after purchasing it)
              </span>
            </div>
            <button disabled className="btn review-btn my-2">
              Write a product review{" "}
            </button>
          </div>
          <div
            className="col-xl-8 col-lg-8 col-md-8 col-sm-8 mt-2"
            ref={reviewRef}
          >
            <Reviews />
            <div className="buy-section">
              <button className="unfixed-cod-button" onClick={() => checkout()}>
                <i className="bi bi-bag-plus-fill bag-icon"></i>
                <span>Order Now - Cash On Delivery </span>
              </button>
            </div>
          </div>
        </div> */}
      </div>
      {/* <button className="fixed-cod-button" onClick={() => checkout()}>
        <i className="bi bi-bag-plus-fill bag-icon"></i>
        <span>Order Now - Cash On Delivery </span>
      </button> */}
      <MainFooter />
    </>
  );
};

export default ProductView;
